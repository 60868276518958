import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const renderTags = group => (
  <ul className="tag-container">
    {group.map(g => (
      <li key={g.tag} className="tag">
        {/* <Link to={`/tags/${g.tag}`}>
          {g.tag} ({g.totalCount})
        </Link> */}
        <Link to={`/tags/${g.tag}`}>{g.tag}</Link>
      </li>
    ))}
  </ul>
)

export default ({ data }) => (
  <Layout>
    <SEO title="Tags" />
    <div className="container full no-margin">
      <div className="container textCenter">
        <h2>Tags</h2>
      </div>
      <div className="container no-padding">
        {renderTags(data.allListJson.group)}
        <div className="clear"></div>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query tagsQuery {
    allListJson {
      group(field: tags) {
        tag: fieldValue
        totalCount
      }
    }
  }
`
